import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { constants } from "./config/constants.js"
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// react-intl i18n
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import it from 'react-intl/locale-data/it';
import messages_it from "./config/i18n/it.json";
import messages_en from "./config/i18n/en.json";

const messages = {
  'it-IT': messages_it,
  'en-EN': messages_en
};

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const CambioPassword = React.lazy(() => import('./views/Pages/CambioPassword'));
const RecuperoPassword = React.lazy(() => import('./views/Pages/RecuperoPassword'));

// i18n
addLocaleData([...en, ...it]);

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
// NOTA per Firefox: intl.accept_languages;it-IT, it, en-US, en
var initialLocale = (navigator.languages && navigator.languages[0])
  || navigator.language
  || navigator.userLanguage
  || "it-IT";

class App extends Component {

  constructor(props) {
    super(props);
    // Fallback "estrema" in caso di browser con proprietà personalizzate (es. Firefox con proprietà "intl.accept_languages")
    if (!initialLocale.includes("-")) {
      initialLocale = "it-IT"
    }
    this.state = {
      locale: initialLocale
    }
    this.onChangeLanguage = this.onChangeLanguage.bind(this)
  }



  onChangeLanguage(loc) {
    // console.log("onChangeLanguage")
    // console.log(loc)
    this.setState({ locale: loc })
  }



  render() {
    return (
      <IntlProvider
        key={this.state.locale}
        locale={this.state.locale}
        formats={constants.formats}
        defaultFormats={constants.formats}
        messages={messages[this.state.locale]}>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} selectedLocale={this.state.locale} onChangeLanguage={this.onChangeLanguage}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} selectedLocale={this.state.locale} onChangeLanguage={this.onChangeLanguage}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} selectedLocale={this.state.locale} onChangeLanguage={this.onChangeLanguage}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} selectedLocale={this.state.locale} onChangeLanguage={this.onChangeLanguage}/>} />
              <Route exact path="/cambioPwd" name="Cambio password" render={props => <CambioPassword {...props} selectedLocale={this.state.locale} onChangeLanguage={this.onChangeLanguage}/>} />
              <Route exact path="/recuperoPwd" name="Recupero password" render={props => <RecuperoPassword {...props} selectedLocale={this.state.locale} onChangeLanguage={this.onChangeLanguage}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props} selectedLocale={this.state.locale} onChangeLanguage={this.onChangeLanguage}/>} />
            </Switch>
            <ToastContainer />
          </React.Suspense>
        </HashRouter>
      </IntlProvider>
    );
  }
}

export default App;
